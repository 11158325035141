import { auth } from '../services/auth/index';

const handleErrors = (res, path) => {
  if (!res.ok) throw res.json();
  else return res.json();
};

export const argsToString = (args = {}) => {
  const keys = Object.keys(args);
  if (keys.length === 0) return '';
  return (
    '?' + keys.map((key) => `${key}=${JSON.stringify(args[key])}`).join('&')
  );
};

function fetch2(
  path,
  params = null,
  method = METHOD.GET,
  body,
  fetchOptions = { useAuth: false },
) {
  const authEnable = fetchOptions.useAuth;
  const options = {
    method,
    headers: Object.assign(
      {},
      {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      // authEnable ? { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdHVkZW50SWQiOiI2MDg3Nzk3NjIwIiwiY2NyYmFyY29kZSI6IjY4MzgxOTEzNjMwIiwiZXhwIjoxNjUwNjUzMTQyLCJpYXQiOjE2NTAwNDgzNDJ9.tRg7pJwTdl1uF8scvwoKLU-EfuyHqYRHb0vW4iqNZDo` } : {},
      authEnable ? { Authorization: `Bearer ${auth.getToken()}` } : {},
    ),
  };
  if (body) {
    options.body = JSON.stringify(body);
  }

  const callPath = params ? `${path}${argsToString(params)}` : path;

  console.log('url is ', method, callPath, body, authEnable);
  //   console.time(`time for url ${callPath}`);
  return fetch(callPath, options)
    .catch((error) => {
      throw error;
    })
    .then((res) => handleErrors(res, callPath));
}

export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export default fetch2;
