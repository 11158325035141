import React, { useState, useEffect, useRef, useCallbackRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

import {
  getCovidPreScreenQuestionsStatus,
  answerCovidPreScreenQuestions,
} from "../services/api";
import auth from "../services/auth";

import Header from "../components/header";
import Footer from "../components/footer";

import { COVID_PRE_SCREEN_QUESTIONS_STATUS, USER_TYPE } from "../utils/utils";

import decode from "jwt-decode";

function CovidPreScreenQuestions() {
  const { language } = useParams();
  const { t, i18n } = useTranslation(["covidPreScreenQuestions"]);
  const [inputs, setInputs] = useState({
    q1: "",
    q2: "",
    q3: "",
  });
  const [showValidate, setShowValidate] = useState(false);
  const [status, setStatus] = useState(COVID_PRE_SCREEN_QUESTIONS_STATUS.NONE);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const [isStudent, setIsStudent] = useState(
    decode(auth.getToken()).userType === USER_TYPE.STUDENT ? true : false
  );

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);
  
  useEffect(() => {
    getCovidPreScreenQuestionsStatus()
      .then((results) => {
        if (
          results &&
          results.results &&
          results.results.covidPreScreenQuestionsStatus
        ) {
          setStatus(results.status);
        }
      })
      .catch((err) => {
        console.log("getCovidPreScreenQuestionsStatus err", err);
      });
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => {
      return { ...values, [name]: value };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      console.log("valid");
      answerCovidPreScreenQuestions(inputs)
        .then((results) => {
          if (results && results.status === "success") {
            console.log("success results.status", results.status);
            setErrorMessage(null);
            navigate(`/${language}/statusPage`);
          }
        })
        .catch((err) => err)
        .then((error) => {
          console.log("answerCovidPreScreenQuestions error", error);
          setErrorMessage(error.err.message);
        })
        .catch((err) => {
          console.log("answerCovidPreScreenQuestions error", err);
          setErrorMessage("");
        });
    } else {
      console.log("notValid");
    }
    console.log(inputs);
  };

  const handleValidation = () => {
    let formIsValid = true;

    if (!inputs["q1"] || inputs["q1"] === "") {
      formIsValid = false;
    }
    if (!inputs["q2"] || inputs["q2"] === "") {
      formIsValid = false;
    }
    if (!inputs["q3"] || inputs["q3"] === "") {
      formIsValid = false;
    }

    if (!formIsValid) {
      setShowValidate(true);
    } else {
      setShowValidate(false);
    }
    return formIsValid;
  };

  return (
    <>
      <Header />
      <div className="container-fluid page">
        <div className="container questionnaire">
          <section className="row">
            <div className="col-md-12 block">
              <form
                className="form questionnaire-content"
                id="form-questionnaire"
                noValidate
                onSubmit={handleSubmit}
              >
                <div>
                  {isStudent ? (
                    <h2>{t("title.student")}</h2>
                  ) : (
                    <h2>{t("title.staff")}</h2>
                  )}
                  <h2>{t("title2")}</h2>
                  <h4 className="error">{t("subTitle")}</h4>
                  {
                    // status === COVID_PRE_SCREEN_QUESTIONS_STATUS.NONE
                    true ? (
                      <>
                        <div className="form-group" onChange={handleChange}>
                          <label htmlFor="q1" className="question">
                            <span className="required-mark error">*</span>
                            {t("question1.question")}
                          </label>
                          {showValidate && inputs["q1"] === "" ? (
                            <span className="error">
                              <br />
                              {t("question1.validation")}
                            </span>
                          ) : null}
                          <fieldset>
                            <div className="radio">
                              <label>
                                <input
                                  type="radio"
                                  name="q1"
                                  value="yes"
                                  style={{ marginTop: 2 }}
                                  required="required"
                                />
                                {t("question1.yes")}
                              </label>
                            </div>
                            <div className="radio">
                              <label>
                                <input
                                  type="radio"
                                  name="q1"
                                  value="no"
                                  style={{ marginTop: 2 }}
                                  required="required"
                                />
                                {t("question1.no")}
                              </label>
                            </div>
                          </fieldset>
                        </div>
                        <div className="form-group" onChange={handleChange}>
                          <label htmlFor="q2" className="question">
                            <span className="required-mark error">*</span>
                            {t("question2.question")}
                          </label>
                          {showValidate && inputs["q2"] === "" ? (
                            <span className="error">
                              <br />
                              {t("question2.validation")}
                            </span>
                          ) : null}
                          <fieldset>
                            <div className="radio">
                              <label>
                                <input
                                  type="radio"
                                  name="q2"
                                  value="yes"
                                  style={{ marginTop: 2 }}
                                  required="required"
                                />
                                {t("question2.yes")}
                              </label>
                            </div>
                            <div className="radio">
                              <label>
                                <input
                                  type="radio"
                                  name="q2"
                                  value="no"
                                  style={{ marginTop: 2 }}
                                  required="required"
                                />
                                {t("question2.no")}
                              </label>
                            </div>
                          </fieldset>
                        </div>
                        <div className="form-group" onChange={handleChange}>
                          <label htmlFor="q3" className="question">
                            <span className="required-mark error">*</span>
                            {t("question3.question.row1")}<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("question3.question.row2")}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("question3.question.row3")}
                          </label>
                          {showValidate && inputs["q3"] === "" ? (
                            <span className="error">
                              <br />
                              {t("question3.validation")}
                            </span>
                          ) : null}
                          <fieldset>
                            <div className="radio">
                              <label>
                                <input
                                  type="radio"
                                  name="q3"
                                  value="yes"
                                  style={{ marginTop: 2 }}
                                  required="required"
                                />
                                {t("question3.yes")}
                              </label>
                            </div>
                            <div className="radio">
                              <label>
                                <input
                                  type="radio"
                                  name="q3"
                                  value="no"
                                  style={{ marginTop: 2 }}
                                  required="required"
                                />
                                {t("question3.no")}
                              </label>
                            </div>
                          </fieldset>
                        </div>
                        <hr />
                        {showValidate ? (
                          <div id="error-alert" className="alert alert-danger">
                            {t("validation.pleaseFillRequired")}
                          </div>
                        ) : null}
                        {errorMessage ? (
                          <div id="error-alert" className="alert alert-danger">
                            {t("validation.somethingWentWrong")}{" "}
                            {`${errorMessage}`}
                          </div>
                        ) : null}
                        <div className="questionnaire-action text-right">
                          <button
                            id="submit"
                            type="submit"
                            className="btn btn-info"
                          >
                            {t("submitButton")}
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-sm-6">
                            <label style={{ marginTop: 8 }}>
                              {isStudent ? "บัณฑิต" : null}
                              {t("alreadyAnswer")}
                            </label>
                          </div>
                          <div className="col-sm-6 text-right">
                            <Link to={`/${language}/statusPage`}>
                              <button className="btn btn-info">
                                {t("backHomeButton")}
                              </button>
                            </Link>
                          </div>
                        </div>
                      </>
                    )
                  }
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default CovidPreScreenQuestions;
