import moment from "moment";

export const COVID_PRE_SCREEN_QUESTIONS_STATUS = {
  NONE: "NONE",
  GROUP_0: "GROUP_0",
  GROUP_1: "GROUP_1",
  GROUP_2: "GROUP_2",
  GROUP_3: "GROUP_3",
};

export const COVID_PRE_SCREEN_QUESTIONS_STATUS_TEXT = {
  NONE: "ยังไม่ได้ประเมิน",
  GROUP_0: "ไม่เสี่ยง",
  GROUP_1: "ความเสี่ยงน้อย",
  GROUP_2: "ความเสี่ยงปานกลาง",
  GROUP_3: "ความเสี่ยงสูง",
};

export const COVID_PRE_SCREEN_QUESTIONS_STATUS_COLOR = {
  NONE: "#31b0d5",
  GROUP_0: "#5cb85c",
  GROUP_1: "#f0ad4e",
  GROUP_2: "#f0ad4e",
  GROUP_3: "#d9534f",
};

export const USER_TYPE = {
  STUDENT: "student",
  STAFF: "staff"
}

export const monthToMonthThai = (month) => {
  switch (month) {
    case "1":
      return "มกราคม";
    case "2":
      return "กุมภาพันธ์";
    case "3":
      return "มีนาคม";
    case "4":
      return "เมษายน";
    case "5":
      return "พฤษภาคม";
    case "6":
      return "มิถุนายน";
    case "7":
      return "กรกฎาคม";
    case "8":
      return "สิงหาคม";
    case "9":
      return "กันยายน";
    case "10":
      return "ตุลาคม";
    case "11":
      return "พฤศจิกายน";
    case "12":
      return "ธันวาคม";
    default:
      return "";
  }
};

export const dateTimeToText = (dateTime) => {
  moment.locale("th");
  const date = moment(dateTime).format("DD");
  const month_thai = monthToMonthThai(moment(dateTime).format("M"));
  const year = String(
    parseInt(moment(dateTime).format("YYYY")) + 543
  );
  const hour = moment(dateTime).format("HH");
  const minute = moment(dateTime).format("mm");
  const dateTimeText =
    "วันที่ " +
    date +
    " " +
    month_thai +
    " พ.ศ." +
    year +
    " เวลา " +
    hour +
    "." +
    minute +
    " น.";
  return dateTimeText;
};

export const dateTimeToDateText = (dateTime) => {
  moment.locale("th");
  const date = moment(dateTime).format("DD");
  const month_thai = monthToMonthThai(moment(dateTime).format("M"));
  const year = String(
    parseInt(moment(dateTime).format("YYYY")) + 543
  );
  const dateTimeDateText = "วันที่ " + date + " " + month_thai + " พ.ศ." + year;
  return dateTimeDateText;
};

export const dateTimeToTimeText = (dateTime) => {
  moment.locale("th");
  const hour = moment(dateTime).format("HH");
  const minute = moment(dateTime).format("mm");
  const dateTimeTimeText = " เวลา " + hour + "." + minute + " น.";
  return dateTimeTimeText;
};
