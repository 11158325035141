import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./i18n";

import "./css/app.css";
import "./css/bootstrap-datetimepicker.css";
import "./css/cropper.css";
import "./css/main.css";
import "./css/typeahead.css";
import "./css/font-awesome.min.css";

ReactDOM.render(
  <Suspense fallback="...">
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);

reportWebVitals();
