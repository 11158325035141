import logo from "./logo.svg";
import "./App.css";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef, useCallbackRef } from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Expenses from "./routes/expenses";
import Invoices from "./routes/invoices";
import Test from "./routes/test";
import Home from "./routes/home";
import CovidScreenFirst from "./routes/covidScreenFirst";
import CovidPreScreenQuestions from "./routes/covidPreScreenQuestions";
import CovidPreScreenQuestionsFreeForm from "./routes/covidPreScreenQuestionsFreeForm";
import StatusPage from "./routes/statusPage";
import PageNotFound from "./routes/pageNotFound";
import ProtectedRoute from "./routes/ProtectedRoute";

function App() {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/:language/statusPage" element={<StatusPage />} />
        <Route
          path="/:language/covidPreScreenQuestions"
          element={<CovidPreScreenQuestions />}
        />
        {/* <Route path="/:language/covidScreen" element={<CovidScreenFirst />} /> */}
      </Route>
      <Route
        path="/:language/covidPreScreenQuestionsFreeForm"
        element={<CovidPreScreenQuestionsFreeForm />}
      />
      <Route path="/" element={<Navigate to="/th" replace />} />
      <Route path="/:language" element={<Home />} />
      <Route path="/:language/:userType" element={<Home />} />

      {/* <Route path="/:language/home" element={<App />} /> */}

      {/* <Route path="/" element={<App />} />
      <Route path="home" element={<Home />} />
      <Route path="covidScreen" element={<CovidScreenFirst />} />
      <Route path="expenses" element={<Expenses />} />
      <Route path="invoices" element={<Invoices />} />
      <Route path="test" element={<Test />} /> */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
