import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t, i18n } = useTranslation(["footer"]);
  return (
    <footer className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <p>
              {t("title")}
              <br />
              {t("subTitle")}
            </p>
            <p>
              {t("contact.title")}{" "}
              <a href="mailto:ccr@chula.ac.th">{t("contact.detail")}</a>
            </p>
          </div>
          <div className="col-sm-4 text-right">
            <p>
              {t("createdBy.title")}
              <br />
              <a
                href="https://www.facebook.com/clique.chula"
                className="clique"
              ></a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
