import React, { useState, useEffect, useRef, useCallbackRef } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

import logo from "../img/logo.png";

import Footer from "../components/footer";

import { login, loginStaff, loginStaffUrl } from "../services/api";
import auth from "../services/auth";
import { USER_TYPE } from "../utils/utils";

import decode from "jwt-decode";

const Home = () => {
  const { t, i18n } = useTranslation(["home"]);
  const { language } = useParams();
  const { userType } = useParams();
  const [isStudent, setIsStudent] = useState(
    userType === USER_TYPE.STUDENT ? true : false
  );
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [showValidate, setShowValidate] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const locationNow = useLocation();
  const pathnameExcludeLanguage = locationNow.pathname.substring(3);

  useEffect(() => {
    if (!userType) {
      setIsStudent(true);
      navigate(`/${language}/${USER_TYPE.STUDENT}`);
    }
  });

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  const handleChange = (event) => {
    if (
      event.target.name === "studentId" ||
      event.target.name === "staffUsername"
    ) {
      setUsername(event.target.value);
    } else if (
      event.target.name === "studentPassword" ||
      event.target.name === "staffPassword"
    ) {
      setPassword(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let loginApi = null;
    if (isStudent) {
      loginApi = login;
    } else {
      loginApi = loginStaff;
    }
    if (handleValidation()) {
      console.log("login");
      setIsLoggingIn(true);
      loginApi({ username, password })
        .then((results) => {
          console.log("===== login =====");
          console.log("isStudent", isStudent);
          if (results.status === "success") {
            auth.clearUserInAppStorage();
            auth.setToken(results.results.token);
            auth.setUserInfo(results.results.userInfo);
            console.log("login success");
            setIsLoggingIn(false);
            navigate(`/${language}/statusPage`);
          }
        })
        .catch((err) => {
          console.log("login err", err);
        });
    } else {
      console.log("notValid");
      setShowValidate(true);
    }
  };

  const handleValidation = () => {
    console.log("handleValidation");
    let formIsValid = true;

    if (!username || username === "") {
      formIsValid = false;
    }
    if (!password || password === "") {
      formIsValid = false;
    }

    if (!formIsValid) {
      console.log("handleValidation formIsValid", formIsValid);
      setShowValidate(true);
    } else {
      console.log("handleValidation formIsValid", formIsValid);
      setShowValidate(false);
    }
    return formIsValid;
  };

  const check = () => {
    console.log("===== check =====");
    const encodedToken = auth.getToken();
    console.log("getToken", auth.getToken());
    const decodeToken = decode(encodedToken);
    console.log("token", decodeToken);
    console.log("isAuthenticated", auth.isAuthenticated());
    console.log(
      "getTokenExpirationDate",
      auth.getTokenExpirationDate(encodedToken)
    );
    console.log("isExpiredToken", auth.isExpiredToken(encodedToken));
    console.log("getUserInfo", auth.getUserInfo());
  };

  const clear = () => {
    console.log("===== clear =====");
    auth.clearUserInAppStorage();
    console.log("getToken", auth.getToken());
  };

  return (
    <>
      <header className="container-fluid page header compact" id="app">
        <div className="container">
          <section className="row">
            <div className="col-sm-12">
              <img src={logo} className="logo" alt="Logo" />
              <p className="title">
                {t("title")}
                <br />
                <b className="text-header">{t("subTitle.part1")}</b>
                <br />
                {t("subTitle.part2")}
              </p>
            </div>
          </section>

          <section className="row">
            <div className="col-sm-6 col-sm-push-3 block login">
              <input
                type="hidden"
                name="_token"
                value="JKQhuZhaaZPb0H5Y6x2E2gQmuFF0W2qtfIO5TIls"
              />
              <div className="clearfix">
                <span className="pull-right">
                  {language === "th" ? (
                    <>
                      <b>{t("switchLanguage.thai")}</b> /
                      <Link to={`/en${pathnameExcludeLanguage}`}>
                        {/* <Link to="/en/vaccine-register"> */}
                        {t("switchLanguage.english")}
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to={`/th${pathnameExcludeLanguage}`}>
                        {t("switchLanguage.thai")}
                      </Link>
                      / <b>{t("switchLanguage.english")}</b>
                    </>
                  )}
                </span>
                <span className="pull-left text-header">
                  {t("loginFormTitle")}
                </span>
              </div>
              <ul
                className="nav nav-pills nav-justified"
                style={{ marginBottom: 15 }}
              >
                <li
                  role="presentation"
                  onClick={() => {
                    setIsStudent(true);
                    navigate(`/${language}/${USER_TYPE.STUDENT}`);
                  }}
                  className={isStudent ? "active" : null}
                >
                  <span
                    aria-controls="login-student"
                    role="tab"
                    data-toggle="tab"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {t("student.title")}
                  </span>
                </li>
                <li
                  role="presentation"
                  onClick={() => {
                    setIsStudent(false);
                    navigate(`/${language}/${USER_TYPE.STAFF}`);
                  }}
                  className={isStudent ? null : "active"}
                >
                  <span
                    aria-controls="login-student"
                    role="tab"
                    data-toggle="tab"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {t("staff.title")}
                  </span>
                </li>
              </ul>

              <form
                className="form"
                id="form-login"
                noValidate
                onSubmit={handleSubmit}
                onChange={handleChange}
              >
                <div className="tab-content">
                  {showValidate ? (
                    <div class="alert alert-danger">
                      <span class="glyphicon glyphicon-warning-sign"></span>{" "}
                      {t("validation.pleaseFillTheForm")}
                    </div>
                  ) : null}
                  {errorMessage ? (
                    <div class="alert alert-danger">
                      <span class="glyphicon glyphicon-warning-sign"></span>{" "}
                      {t("validation.userNotFound")}
                    </div>
                  ) : null}

                  <div
                    role="tabpanel"
                    className={isStudent ? "tab-pane active" : "tab-pane"}
                    id="login-student"
                  >
                    <div className="alert alert-info">{t("student.info")}</div>
                    <div className="form-group">
                      <label>{t("student.studentId")}</label>
                      <input
                        name="studentId"
                        type="text"
                        className="form-control"
                        value={username}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>{t("student.password")}</label>
                      <input
                        name="studentPassword"
                        type="password"
                        className="form-control"
                        value={password}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div
                    role="tabpanel"
                    className={isStudent ? "tab-pane" : "tab-pane active"}
                    id="login-staff"
                  >
                    <div className="form-group">
                      <label>{t("staff.username")}</label>
                      <input
                        name="staffUsername"
                        type="text"
                        className="form-control"
                        value={username}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>{t("staff.password")}</label>
                      <input
                        name="staffPassword"
                        type="password"
                        className="form-control"
                        value={password}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <button
                  id="submit"
                  type="submit"
                  className="btn btn-success-shaded btn-block"
                >
                  {t("loginButton")}
                </button>
              </form>
            </div>

            {/* <button
              className="btn btn-success-shaded btn-block"
              onClick={check}
            >
              check
            </button>

            <button
              className="btn btn-success-shaded btn-block"
              onClick={clear}
            >
              clear
            </button> */}
          </section>
        </div>
      </header>

      <Footer />
    </>
  );
};
export default Home;
