import fetch, { METHOD } from "../../utils/Fetch";

// Real Server
export const basedURL = process.env.REACT_APP_API_SERVER_URL;

// GET AUTH /covidPreScreenQuestions/status
const getCovidPreScreenQuestionsStatusUrl = `${basedURL}/covidPreScreenQuestions/status`;
export const getCovidPreScreenQuestionsStatus = () => {
  return fetch(getCovidPreScreenQuestionsStatusUrl, null, METHOD.GET, null, {
    useAuth: true,
  });
};

// POST AUTH /covidPreScreenQuestions/answer
const answerCovidPreScreenQuestionsUrl = `${basedURL}/covidPreScreenQuestions/answer`;
export const answerCovidPreScreenQuestions = (inputs) => {
  return fetch(answerCovidPreScreenQuestionsUrl, null, METHOD.POST, inputs, {
    useAuth: true,
  });
};

// POST NO_AUTH /users/login
const loginUrl = `${basedURL}/users/login`;
export const login = ({ username, password }) => {
  const body = {
    username,
    password,
  };
  return fetch(loginUrl, null, METHOD.POST, body);
};

// POST NO_AUTH /users/loginStaff
const loginStaffUrl = `${basedURL}/users/loginStaff`;
export const loginStaff = ({ username, password }) => {
  const body = {
    username,
    password,
  };
  return fetch(loginStaffUrl, null, METHOD.POST, body);
};

// GET AUTH /covidPreScreenQuestions/answers
const getAllAnswersUrl = `${basedURL}/covidPreScreenQuestions/answers`;
export const getAllAnswers = () => {
  return fetch(getAllAnswersUrl, null, METHOD.GET, null, {
    useAuth: true,
  });
};

// POST NO_AUTH /covidPreScreenQuestions/answerFreeForm
const answerCovidPreScreenQuestionsFreeFormUrl = `${basedURL}/covidPreScreenQuestions/answerFreeForm`;
export const answerCovidPreScreenQuestionsFreeForm = (inputs) => {
  return fetch(answerCovidPreScreenQuestionsFreeFormUrl, null, METHOD.POST, inputs);
};

// // GET /covidPreScreenQuestions/:studentId
// export const getLatestAnswer = (studentId) => {
//   return fetch(
//     `${basedURL}/covidPreScreenQuestions/${studentId}`,
//     null,
//     METHOD.POST,
//     null,
//     {
//       useAuth: true,
//     }
//   );
// };

// // GET /covidPreScreenQuestions/:studentId
// export const getLatestAnswer = (studentId) => {
//   return fetch(basedURL + "/covidPreScreenQuestions/" + studentId, {
//     method: "GET",
//     headers: {
//       Accept: "application/form-data",
//       "Content-Type": "application/json",
//     },
//   }).then((res) => handleErrors(res));
// };
