import logo from "../img/logo.png";

const PageNotFound = () => {
  return (
    <>
      <header class="container-fluid page header compact" id="app">
        <div class="container">
          <section class="row">
            <div class="col-sm-12">
              <img src={logo} className="logo" alt="Logo" />
              <p class="title">
                ระบบลงทะเบียนบัณฑิตคัดกรองโควิด
                <br />
                <b class="text-header">พิธีพระราชทานปริญญาบัตร</b>
                <br />
                จุฬาลงกรณ์มหาวิทยาลัย ปีการศึกษา 2565
              </p>
            </div>
          </section>
        </div>
      </header>

      <main style={{ padding: "1rem" }}>
        <h1 style={{ textAlign: "center" }}>
          Sorry, the page you are looking for could not be found.
        </h1>
      </main>

      <footer class="container-fluid">
        <div class="container">
          <div class="row">
            <div class="col-sm-8">
              <p>
                ระบบลงทะเบียนบัณฑิต
                <br />
                พิธีพระราชทานปริญญาบัตร จุฬาลงกรณ์มหาวิทยาลัย ปีการศึกษา 2565
              </p>
              <p>
                ติดต่อ <a href="mailto:ccr@chula.ac.th">ccr@chula.ac.th</a>
              </p>
            </div>
            <div class="col-sm-4 text-right">
              <p>
                จัดทำระบบโดย
                <br />
                <a
                  href="https://www.facebook.com/clique.chula"
                  target="_blank"
                  class="clique"
                />
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default PageNotFound;
