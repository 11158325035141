import React, { useState, useEffect, useRef, useCallbackRef } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { answerCovidPreScreenQuestionsFreeForm } from "../services/api";

import Header from "../components/header";
import Footer from "../components/footer";

import {
  COVID_PRE_SCREEN_QUESTIONS_STATUS,
  COVID_PRE_SCREEN_QUESTIONS_STATUS_TEXT,
  COVID_PRE_SCREEN_QUESTIONS_STATUS_COLOR,
  dateTimeToText,
  dateTimeToDateText,
  dateTimeToTimeText,
} from "../utils/utils";

function CovidPreScreenQuestions() {
  const { language } = useParams();
  const { t, i18n } = useTranslation(["covidPreScreenQuestionsFreeForm"]);
  const [inputs, setInputs] = useState({
    firstname: "",
    lastname: "",
    q1: "",
    q2: "",
    q3: "",
  });
  const [showValidate, setShowValidate] = useState(false);
  const [status, setStatus] = useState(COVID_PRE_SCREEN_QUESTIONS_STATUS.NONE);
  const [errorMessage, setErrorMessage] = useState(null);
  const [latestAnswer, setLatestAnswer] = useState({});
  const [allAnswers, setAllAnswers] = useState([]);
  const locationNow = useLocation();
  const [isResult, setIsResult] = useState(false);
  const navigate = useNavigate();

  //   useEffect(() => {
  //     console.log("locationNow", locationNow);
  //     const pathname = locationNow.pathname;
  //     if (pathname.indexOf("/statusPageFreeForm") !== -1) {
  //       setIsResult(true);
  //     } else {
  //       setIsResult(false);
  //     }
  //   }, []);

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => {
      return { ...values, [name]: value };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      console.log("valid");
      answerCovidPreScreenQuestionsFreeForm(inputs)
        .then((results) => {
          if (results && results.status === "success") {
            console.log("success results.status", results.status);
            setErrorMessage(null);
            setStatus(results.results[0].status);
            setLatestAnswer(results.results[0]);
            setIsResult(true);
            // navigate(`/${language}/statusPageFreeForm`);
          }
        })
        .catch((err) => err)
        .then((error) => {
          console.log("answerCovidPreScreenQuestionsFreeForm error", error);
          setErrorMessage(error.err.message);
        })
        .catch((err) => {
          console.log("answerCovidPreScreenQuestionsFreeForm error", err);
          setErrorMessage("");
        });
    } else {
      console.log("notValid");
    }
    console.log(inputs);
  };

  const handleValidation = () => {
    let formIsValid = true;

    if (!inputs["firstname"] || inputs["firstname"] === "") {
      formIsValid = false;
    }
    if (!inputs["lastname"] || inputs["lastname"] === "") {
      formIsValid = false;
    }
    if (!inputs["q1"] || inputs["q1"] === "") {
      formIsValid = false;
    }
    if (!inputs["q2"] || inputs["q2"] === "") {
      formIsValid = false;
    }
    if (!inputs["q3"] || inputs["q3"] === "") {
      formIsValid = false;
    }

    if (!formIsValid) {
      setShowValidate(true);
    } else {
      setShowValidate(false);
    }
    return formIsValid;
  };

  return (
    <>
      <Header />
      {isResult ? (
        <div className="container-fluid page">
          <div className="container questionnaire">
            <section className="row">
              <div className="col-md-12 block">
                <div>
                  <h2>
                    สำหรับบัณฑิต อาจารย์ บุคลากร
                    นิสิตช่วยงานที่จะมาเข้าซ้อมและถ่ายภาพหมู่
                  </h2>
                  <h2>แบบประเมินความเสี่ยงโควิด-19</h2>
                  <h4>สถานะการกรอกแบบประเมินความเสี่ยงโควิด-19</h4>
                  <h4 className="error">
                    กรุณาประเมิน ล่วงหน้าไม่เกิน 3 วัน ก่อนมางาน
                  </h4>
                  {status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_0 ||
                  status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_1 ||
                  status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_2 ||
                  status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_3 ? (
                    <>
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <label style={{ marginTop: 8 }}>
                            กรอกแบบประเมินความเสี่ยงโควิด-19 สำเร็จ
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <label
                            style={{
                              fontSize: 30,
                            }}
                          >
                            {latestAnswer.firstname}&nbsp;&nbsp;&nbsp;
                            {latestAnswer.lastname}
                          </label>
                          <br />
                          <label
                            style={{
                              marginTop: 15,
                              marginBottom: 15,
                              backgroundColor:
                                COVID_PRE_SCREEN_QUESTIONS_STATUS_COLOR[status],
                              fontSize: 30,
                              padding: 10,
                              borderRadius: 5,
                              boxShadow: "0px 0px 8px 3px rgba(0,0,0,0.3)",
                            }}
                          >
                            {COVID_PRE_SCREEN_QUESTIONS_STATUS_TEXT[status]}
                          </label>
                          <br />
                          {latestAnswer.createdAt ? (
                            <label>
                              ประเมิน
                              {dateTimeToDateText(latestAnswer.createdAt)}
                              <br />
                              {dateTimeToTimeText(latestAnswer.createdAt)}
                            </label>
                          ) : null}
                          <br />
                          <label>ref id:&nbsp; {latestAnswer.id}</label>
                          <br />
                          <h5 className="error">
                            กรุณาเก็บภาพหน้าจอนี้ไว้เป็นหลักฐาน
                          </h5>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_1 ||
                  status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_2 ||
                  status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_3 ? (
                    <div className="row">
                      <div className="col-sm-12">
                        <label style={{ marginTop: 8 }}>
                          กรุณาตรวจ ATK (แบบ Professional Use)
                          จากสถานพยาบาลหรือร้านยาที่มีใบรับรองผล ATK และ
                          <u>
                            นำใบรับรองผลการตรวจ ATK ผลลบ มาในวันซ้อมรอบที่ 1
                            และรอบที่ 2
                          </u>
                          &nbsp;(ไม่สามารถซื้อชุดตรวจ ATK แบบ Home Use
                          มาตรวจด้วยตนเองได้)
                        </label>
                        <br />
                        <label htmlFor="work-status" className="question">
                          หากมีข้อสงสัย กรุณาติดต่อ
                          <br />
                          ศูนย์บริการสุขภาพแห่งจุฬาลงกรณ์มหาวิทยาลัย โทร.
                          02-218-0568 (ในวันราชการ เวลา 08.00 - 15.00 น.)
                        </label>
                      </div>
                    </div>
                  ) : null}

                  <div
                    className="row"
                    style={{ marginTop: 15, marginBottom: 15 }}
                  >
                    <div className="col-sm-12 text-center">
                      <Link to={`/${language}`}>
                        <button className="btn btn-info">กลับหน้าหลัก</button>
                      </Link>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div className="container-fluid page">
          <div className="container questionnaire">
            <section className="row">
              <div className="col-md-12 block">
                <form
                  className="form questionnaire-content"
                  id="form-questionnaire"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <div>
                    <h2>{t("title")}</h2>
                    <h2>{t("title2")}</h2>
                    <h4 className="error">{t("subTitle")}</h4>

                    <div className="form-group" onChange={handleChange}>
                      <label htmlFor="firstname" className="question">
                        <span className="required-mark error">*</span>
                        {t("firstname.title")}
                      </label>
                      {showValidate && inputs["firstname"] === "" ? (
                        <span className="error">
                          <br />
                          {t("firstname.validation")}
                        </span>
                      ) : null}
                      <fieldset>
                        <div className="">
                          <input
                            type="text"
                            name="firstname"
                            required="required"
                            className={`input-xs form-control 
                              ${
                                showValidate && inputs["firstname"] === ""
                                  ? "input-show-validate"
                                  : ""
                              }`}
                            value={inputs["firstname"]}
                          />
                        </div>
                      </fieldset>
                    </div>

                    <div className="form-group" onChange={handleChange}>
                      <label htmlFor="lastname" className="question">
                        <span className="required-mark error">*</span>
                        {t("lastname.title")}
                      </label>
                      {showValidate && inputs["lastname"] === "" ? (
                        <span className="error">
                          <br />
                          {t("lastname.validation")}
                        </span>
                      ) : null}
                      <fieldset>
                        <div className="">
                          <input
                            type="text"
                            name="lastname"
                            required="required"
                            className={`input-xs form-control 
                              ${
                                showValidate && inputs["lastname"] === ""
                                  ? "input-show-validate"
                                  : ""
                              }`}
                            value={inputs["lastname"]}
                          />
                        </div>
                      </fieldset>
                    </div>

                    <div className="form-group" onChange={handleChange}>
                      <label htmlFor="q1" className="question">
                        <span className="required-mark error">*</span>
                        {t("question1.question")}
                      </label>
                      {showValidate && inputs["q1"] === "" ? (
                        <span className="error">
                          <br />
                          {t("question1.validation")}
                        </span>
                      ) : null}
                      <fieldset>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="q1"
                              value="yes"
                              style={{ marginTop: 2 }}
                              required="required"
                            />
                            {t("question1.yes")}
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="q1"
                              value="no"
                              style={{ marginTop: 2 }}
                              required="required"
                            />
                            {t("question1.no")}
                          </label>
                        </div>
                      </fieldset>
                    </div>
                    <div className="form-group" onChange={handleChange}>
                      <label htmlFor="q2" className="question">
                        <span className="required-mark error">*</span>
                        {t("question2.question")}
                      </label>
                      {showValidate && inputs["q2"] === "" ? (
                        <span className="error">
                          <br />
                          {t("question2.validation")}
                        </span>
                      ) : null}
                      <fieldset>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="q2"
                              value="yes"
                              style={{ marginTop: 2 }}
                              required="required"
                            />
                            {t("question2.yes")}
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="q2"
                              value="no"
                              style={{ marginTop: 2 }}
                              required="required"
                            />
                            {t("question2.no")}
                          </label>
                        </div>
                      </fieldset>
                    </div>
                    <div className="form-group" onChange={handleChange}>
                      <label htmlFor="q3" className="question">
                        <span className="required-mark error">*</span>
                        {t("question3.question.row1")}
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {t("question3.question.row2")}
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {t("question3.question.row3")}
                      </label>
                      {showValidate && inputs["q3"] === "" ? (
                        <span className="error">
                          <br />
                          {t("question3.validation")}
                        </span>
                      ) : null}
                      <fieldset>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="q3"
                              value="yes"
                              style={{ marginTop: 2 }}
                              required="required"
                            />
                            {t("question3.yes")}
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input
                              type="radio"
                              name="q3"
                              value="no"
                              style={{ marginTop: 2 }}
                              required="required"
                            />
                            {t("question3.no")}
                          </label>
                        </div>
                      </fieldset>
                    </div>
                    <hr />
                    {showValidate ? (
                      <div id="error-alert" className="alert alert-danger">
                        {t("validation.pleaseFillRequired")}
                      </div>
                    ) : null}
                    {errorMessage ? (
                      <div id="error-alert" className="alert alert-danger">
                        {t("validation.somethingWentWrong")} {`${errorMessage}`}
                      </div>
                    ) : null}
                    <div className="questionnaire-action text-right">
                      <button
                        id="submit"
                        type="submit"
                        className="btn btn-info"
                      >
                        {t("submitButton")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
export default CovidPreScreenQuestions;
