import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

import {
  getCovidPreScreenQuestionsStatus,
  getAllAnswers,
} from "../services/api";
import auth from "../services/auth";
import { USER_TYPE } from "../utils/utils";

import Header from "../components/header";
import Footer from "../components/footer";

import {
  COVID_PRE_SCREEN_QUESTIONS_STATUS,
  COVID_PRE_SCREEN_QUESTIONS_STATUS_TEXT,
  COVID_PRE_SCREEN_QUESTIONS_STATUS_COLOR,
  dateTimeToText,
  dateTimeToDateText,
  dateTimeToTimeText,
} from "../utils/utils";

import decode from "jwt-decode";

function StatusPage() {
  const { language } = useParams();
  const { t, i18n } = useTranslation(["statusPage"]);
  const [status, setStatus] = useState(COVID_PRE_SCREEN_QUESTIONS_STATUS.NONE);
  const [latestAnswer, setLatestAnswer] = useState({});
  const [allAnswers, setAllAnswers] = useState([]);
  const [isStudent, setIsStudent] = useState(
    decode(auth.getToken()).userType === USER_TYPE.STUDENT ? true : false
  );
  const navigate = useNavigate();

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);
  
  useEffect(() => {
    getCovidPreScreenQuestionsStatus()
      .then((results) => {
        if (
          results &&
          results.results &&
          results.results.covidPreScreenQuestionsStatus
        ) {
          setStatus(results.results.covidPreScreenQuestionsStatus);
          setLatestAnswer(results.results.data[0]);
        }
      })
      .catch((err) => {
        console.log("getCovidPreScreenQuestionsStatus err", err);
      });
  }, []);

  useEffect(() => {
    getAllAnswers()
      .then((results) => {
        if (results && results.results) {
          console.log("results.results", results.results);
          setAllAnswers(results.results);
        }
      })
      .catch((err) => {
        console.log("getAllAnswers err", err);
      });
  }, []);

  const logout = () => {
    auth.clearUserInAppStorage();
    navigate(`/${language}`);
  };

  return (
    <>
      <Header />
      <div className="container-fluid page">
        <div className="container questionnaire">
          <section className="row">
            <div className="col-md-12 block">
              <div>
                {isStudent ? (
                  <h2>{t("title.student")}</h2>
                ) : (
                  <h2>{t("title.staff")}</h2>
                )}
                <h2>{t("title2")}</h2>
                <h4>{t("subTitle")}</h4>
                <h4 className="error">
                  {t("notice.row1")}
                  <br />
                  {t("notice.row2")}
                  <br />
                  {t("notice.row3")}
                </h4>
                {status === COVID_PRE_SCREEN_QUESTIONS_STATUS.NONE ? (
                  <div className="row">
                    <div className="col-sm-12 text-center">
                      <label style={{ marginTop: 8 }}>
                        {isStudent ? <>{t("noAnswer.student")}</> : null}
                        {t("noAnswer.part1")}
                      </label>
                    </div>
                  </div>
                ) : status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_0 ||
                  status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_1 ||
                  status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_2 ||
                  status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_3 ? (
                  <>
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        <label style={{ marginTop: 8 }}>
                          {isStudent ? <>{t("lastStatus.student")}</> : null}
                          {t("lastStatus.part1")}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        <label
                          style={{
                            marginTop: 15,
                            marginBottom: 15,
                            backgroundColor:
                              COVID_PRE_SCREEN_QUESTIONS_STATUS_COLOR[status],
                            fontSize: 30,
                            padding: 10,
                            borderRadius: 5,
                            boxShadow: "0px 0px 8px 3px rgba(0,0,0,0.3)",
                          }}
                        >
                          {COVID_PRE_SCREEN_QUESTIONS_STATUS_TEXT[status]}
                        </label>
                        <br />
                        {latestAnswer.createdAt ? (
                          <label>
                            {t("lastStatus.part2")}
                            {dateTimeToDateText(latestAnswer.createdAt)}
                            <br />
                            {dateTimeToTimeText(latestAnswer.createdAt)}
                          </label>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
                {status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_1 ||
                status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_2 ||
                status === COVID_PRE_SCREEN_QUESTIONS_STATUS.GROUP_3 ? (
                  <div className="row">
                    <div className="col-sm-12">
                      <label style={{ marginTop: 8 }}>
                        {t("warningNotice.part1")}
                        <u>{t("warningNotice.part2")}</u>
                        &nbsp;
                        {t("warningNotice.part3")}
                      </label>
                      <br />
                      <label htmlFor="work-status" className="question">
                        {t("warningNotice.contact.title")}
                        <br />
                        {t("warningNotice.contact.detail")}
                      </label>
                    </div>
                  </div>
                ) : null}

                <div
                  className="row"
                  style={{ marginTop: 15, marginBottom: 15 }}
                >
                  <div className="col-sm-12 text-center">
                    <Link to={`/${language}/covidPreScreenQuestions`}>
                      <button className="btn btn-info">
                        {t("goToQuestionButton")}
                      </button>
                    </Link>
                  </div>
                </div>

                <hr />

                {allAnswers && allAnswers.length > 0 ? (
                  <>
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        <h4>{t("answerHistory.title")}</h4>
                      </div>
                    </div>
                    <table
                      className="table table-striped"
                      style={{ marginTop: 10 }}
                    >
                      <thead className="table-bordered">
                        <tr>
                          <th className="text-center">
                            {t("answerHistory.table.no")}
                          </th>
                          <th>{t("answerHistory.table.status")}</th>
                          <th>{t("answerHistory.table.date")}</th>
                        </tr>
                      </thead>
                      <tbody className="table-bordered">
                        {allAnswers.map((item, index) => {
                          return (
                            <tr key={index + 1}>
                              <td className="text-center">
                                {allAnswers.length - index}
                              </td>
                              <td>
                                {
                                  COVID_PRE_SCREEN_QUESTIONS_STATUS_TEXT[
                                    item.status
                                  ]
                                }
                              </td>
                              <td>{dateTimeToText(item.createdAt)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                ) : null}

                <hr />
              </div>
            </div>
            <div className="row">
              <div
                className="col-sm-2 col-sm-offset-5"
                style={{ marginTop: 30 }}
              >
                <button className="btn btn-block" onClick={logout}>
                  {t("logoutButton")}
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default StatusPage;
