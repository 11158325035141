import { Navigate, Outlet } from "react-router-dom";
import auth from "../services/auth";

const ProtectedRoute = ({ redirectPath = "/", children }) => {
  const token = auth.getToken();
  console.log("token", token);
  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
