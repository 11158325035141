import { Link, useLocation } from "react-router-dom";
import logoGlow from "../img/logo_glow.png";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Header = () => {
  const { language } = useParams();
  const { t, i18n } = useTranslation(["header"]);
  const locationNow = useLocation();
  const pathnameExcludeLanguage = locationNow.pathname.substring(3);
  return (
    <header className="container-fluid page bg-area compact header header-sm">
      <div className="row">
        <div className="col-sm-12">
          <img src={logoGlow} className="logo" alt="logo" />
          <Link to="/">
            <div className="title">
              {t("title")}
              <br className="visible-xs" />
              {t("subTitle.part1")}
              <br />
              {t("subTitle.part2")}
            </div>
          </Link>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 text-center">
            {language === "th" ? (
              <>
                <b>{t("switchLanguage.thai")}</b> /
                <Link to={`/en${pathnameExcludeLanguage}`}>
                  {/* <Link to="/en/vaccine-register"> */}
                  {t("switchLanguage.english")}
                </Link>
              </>
            ) : (
              <>
                <Link to={`/th${pathnameExcludeLanguage}`}>
                  {t("switchLanguage.thai")}
                </Link>
                / <b>{t("switchLanguage.english")}</b>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
